<template>
  <div class="container">
    <div class="content">
      <!-- 文件类型 -->
      <div class="header-item">
        <el-radio-group v-model="searchStatus" @change="getBrochureList()">
          <el-radio-button :label="100">全部</el-radio-button>
          <el-radio-button :label="1">已开启</el-radio-button>
          <el-radio-button :label="0">已关闭</el-radio-button>
        </el-radio-group>
      </div>

      <!-- 文件名称搜索框 -->
      <div class="header-item">
        <el-input v-model="searchText" placeholder="请输入宣传册名称" suffix-icon="el-icon-search"
          @change="getBrochureList">
        </el-input>
      </div>

      <!-- 添加企业宣传册按钮 -->
      <div class="header-item" style="float: right">
        <el-button @click="$winRedirectPage(1, '#/brochure/edit')" type="primary" icon="el-icon-plus">新增宣传册</el-button>
      </div>

      <!-- 文件夹列表及操作 -->
      <div class="brochure-item">
        <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%; margin-top: 10px" 
          header-row-class-name="table-header-style"
          :data="brochureList"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
          <el-table-column label="封面" width="80">
            <template slot-scope="scope">
              <div class="item-logo" :style="{
                'background-image': `url(${scope.row.configs.logo || $configs.headImg})`}">
              </div>
            </template>
          </el-table-column>
          <el-table-column label="宣传手册" prop="title"></el-table-column>
          <el-table-column label="更新时间" width="400">
            <template slot-scope="scope">
              <p>{{ scope.row.updatedAt | dateFormat('YYYY-MM-DD HH:mm:ss') }}</p>
            </template>
          </el-table-column>
          <el-table-column label="页数" width="100" prop="configs.pageNum"></el-table-column>
          <el-table-column label="创建者" width="100" prop="userName"></el-table-column>
          <el-table-column label="是否启用" width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status"
                @change="updateBrochhureStatus(scope.row)"
                :active-value="1" :inactive-value="0"
                active-color="#1a9afd" inactive-color="#999999"></el-switch>
            </template>
          </el-table-column>
          
          <!-- 删除、重命名 -->
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button @click="updateBrochhureStatus(scope.row, -1)" type="text" style="color: #ff0000">删除</el-button>
              <el-button @click="$winRedirectPage(1, `#/brochure/edit?sid=${scope.row.id}`)" type="text">编辑</el-button>
              <el-button @click="updateBrochure(scope.row)" type="text">重命名</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div style="margin-top: 20px; overflow: hidden">
          <div style="float: right">
            <el-pagination @current-change="getBrochureList" layout="total, prev, pager, next, jumper"
              :background="true"
              :current-page="page.index"
              :page-size="page.number"
              :total="page.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      searchText: '', // 宣传册搜索框字段
      searchStatus: 100, // 宣传册搜索状态
      page: { // 分页查询信息
        index: 1,
        number: 6,
        total: 0
      },
      brochureList: [], // 宣传册列表
    }
  },
  created() {
    this.getBrochureList();
  },
  methods: {
    // 分页查询宣传册列表
    async getBrochureList(pageIndex){
      this.page.index = pageIndex;
      let params = {
        type: 8,
        title: this.searchText,
        status: this.searchStatus == 100 ? "" : this.searchStatus,
        pageIndex: pageIndex || this.page.index,
        pageNumber: this.page.number
      }
      let res = await this.$ZGManager.getMaterialList(params);
      if(res.status == 200 && res.data){
        // 解析宣传册configs
        res.data.list.map(e => e.configs = JSON.parse(e.configs));
        this.brochureList = res.data.list;
        this.page.total = res.data.totalRows;
      }
    },

    // 修改宣传册状态
    async updateBrochhureStatus(item, type){
      if(type == -1){
        let dialog = await this.$confirm('此操作将永久删除该企业宣传册, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        if(dialog != "confirm") return;
      }
      let res = await this.$ZGManager.updateMaterialStatus(item.id, type || item.status);
      if(res.status != 200) return;
      if(type == -1){
        this.getBrochureList();
      }else{
        let index = this.brochureList.findIndex(e => e.id == item.id);
        let data = this.brochureList[index];
        data.status = res.data.status;
        this.brochureList.splice(index, 1, data);
      }
    },

    // 更新宣传册信息
    async updateBrochure(item){
      let dialog = await this.$prompt('请输入宣传册名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.title,
        inputPattern: /^.{2,30}$/,
        inputErrorMessage: '宣传册名称应在2-15个字'
      });
      if(dialog.action != 'confirm') return;
      let configs = item.configs;
      configs.title = dialog.value;
      let res = await this.$ZGManager.updateMaterial(item.id, { title: dialog.value, configs: JSON.stringify(configs) });
      if(res.status != 200) return;
      let index = this.brochureList.findIndex(e => e.id == item.id);
      let data = this.brochureList[index];
      data.title = res.data.title;
      this.brochureList.splice(index, 1, data);
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  .progress{
    display: inline-block;
    width: 200px;
    ::v-deep .el-progress-bar__outer{
      height: 16px!important;
    }
  }
  .title-l1{
    font-size: 14px;
    font-weight: bold;
    color: rgb(78, 78, 78);
    vertical-align: middle;
  }
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}
.breadcrumb{
  padding: 15px 10px;
  background-color: rgb(246, 247, 248);
  border-bottom: 1px solid #e8e8e8;
  margin-top: 10px;
  overflow: hidden;
  ::v-deep .el-breadcrumb{
    width: 800px;
    display: inline-block;
  }
  .nav{
    color: #777;
  }
  .link{
    color: #578bcf;
    cursor: pointer;
  }
  .padding-align{
    padding: 0 10px;
  }
  .number{
    float: right;
    margin-right: 30px;
    color: #777;
  }
}
.table-title{
  color: #578bcf;
  cursor: pointer;
}

.brochure-item{
  .item-logo{
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
}
</style>